import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';
import locationStoreModule from '../locationStoreModule';

export default function useLocationList() {
  const BED_STORE_MODULE_NAME = 'location';

  // Register module
  if (!store.hasModule(BED_STORE_MODULE_NAME)) {
    store.registerModule(BED_STORE_MODULE_NAME, locationStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(BED_STORE_MODULE_NAME)) {
      store.unregisterModule(BED_STORE_MODULE_NAME);
    }
  });

  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    id: 0,
    name: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'location') && !vm.$can('delete', 'location');

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,

    },
    {
      label: t('Tên khu vực'),
      field: 'name',
    },
    {
      label: t('Số tòa nhà'),
      field: 'numberApartments',
      type: 'number',
      width: '120px',
    },

  ];
  const rows = ref([]);
  // filter
  const active = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });
  const analytic = ref(null);

  //   API Call
  const fetchLocations = () => {
    isLoading.value = true;
    store
      .dispatch('location/fetchLocations', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        isLoading.value = false;
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchLocations();
  };

  const deleteLocations = locations => {
    store
      .dispatch('location/deleteLocations', {
        ids: locations.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const onViewDetailItem = val => {
    item.value = val;
  };
  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(active, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.active = val.value;
    } else {
      delete filter.active;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });

  return {
    analytic,
    item,
    columns,
    rows,
    active,
    apartment,
    room,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    fetchLocations,
    deleteLocations,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    t,
  };
}
